<template>
  <div class="powerList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>名称:</span>
      <el-input
        placeholder="请输入权限名称"
        v-model="list.powerName"
      ></el-input>
      <span class="column">所属栏目:</span>
      <el-select
        v-model="value"
        placeholder="请选择"
        clearable
        @clear="onClear"
      >
        <el-option
          v-for="item in classAList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-show="value" v-model="value1" placeholder="请选择">
        <el-option
          v-for="item in classBList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select v-show="value1" v-model="list.columnId" placeholder="请选择">
        <el-option
          v-for="item in classCList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getPowerList()">搜索</el-button>
      <el-button
        type="primary"
        @click="addDialogVisible = true"
        v-if="
          $store.state.powerList.indexOf('system:permission:power:insert') !==
          -1
        "
        >添加</el-button
      ><el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table
      style="width: 100%"
      stripe
      :data="tableData"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="powerId"
        label="权限主键"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="powerName" label="权限名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="powerValue"
        label="权限内容"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="powerDescribe"
        label="权限说明"
      ></el-table-column>
      <el-table-column
        align="center"
        width="100"
        prop="powerManageColumnId"
        label="所属栏目主键"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="powerCreateTime"
        label="权限创建时间"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="onEdit(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:power:update'
              ) !== -1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="onDel(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:power:delete'
              ) !== -1
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      class="btn"
      @click="onAllDelColumn"
      v-if="
        $store.state.powerList.indexOf('system:permission:power:delete') !== -1
      "
      >批量删除</el-button
    >
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <el-dialog
      :close-on-click-modal="false"
      title="添加"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="权限名称">
          <el-input
            v-model="addFromList.powerName"
            placeholder="请输入权限名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限内容">
          <el-input
            v-model="addFromList.powerValue"
            placeholder="请输入权限内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限描述">
          <el-input
            v-model="addFromList.powerDescribe"
            placeholder="请输入权限描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属栏目主键" class="select">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in classAList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-show="value" v-model="value1" placeholder="请选择">
            <el-option
              v-for="item in classBList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-select v-show="value1" v-model="value2" placeholder="请选择">
            <el-option
              v-for="item in classCList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddPowerOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="编辑"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="权限主键">
          <el-input v-model="editFromList.powerId" readonly></el-input>
        </el-form-item>
        <el-form-item label="权限名称">
          <el-input v-model="editFromList.powerName"></el-input>
        </el-form-item>
        <el-form-item label="权限内容">
          <el-input v-model="editFromList.powerValue"></el-input>
        </el-form-item>
        <el-form-item label="权限描述">
          <el-input v-model="editFromList.powerDescribe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditPowerOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { powerList, addPower, editPower, delPower } from "../../api/power";
import { columnList } from "../../api/column";

export default {
  name: "powerList",
  data: () => {
    return {
      value: null,
      value1: null,
      value2: [],
      delValue: [],
      classBList: [],
      classCList: [],
      keyWords: "",
      options: [],
      editDialogVisible: false,
      addDialogVisible: false,
      tableData: [],
      list: {
        columnId: null,
        powerName: "",
        currentPage: 1,
        pageSize: 10,
      },
      addFromList: {
        powerName: "",
        powerValue: "",
        powerDescribe: "",
        powerManageColumnId: "",
      },
      editFromList: {},
      classAList: [],
      pagination: {},
      allColumnList: null,
      delEditList: [],
    };
  },
  watch: {
    value: function () {
      if (this.allColumnList == null) return;
      var list = this.allColumnList.filter((item) => {
        return this.value === item.columnParentId;
      });
      list.map((item) => {
        item.label = item.columnName;
        item.value = item.columnId;
      });

      this.classBList = list;
    },

    value1: function () {
      if (this.allColumnList !== null) {
        var list = this.allColumnList.filter((item) => {
          return this.value1 === item.columnParentId;
        });
        list.map((item) => {
          item.label = item.columnName;
          item.value = item.columnId;
        });
        this.classCList = list;
      }
    },
  },
  computed: {},
  created() {
    this.getPowerList();
    this.getColumnList();
  },
  methods: {
    onClear() {
      this.value = "";
      this.value1 = "";
      this.list.columnId = null;
    },
    async getPowerList() {
      const { data } = await powerList(this.list);
      this.pagination = data.pagination;
      this.tableData = data.list;
    },
    onSearch() {
      if (this.keyWords.length <= 0) {
        this.$message({
          message: "请输入搜索内容",
          type: "warning",
        });
      } else {
        this.keyWords = "";
      }
    },
    async getColumnList() {
      const { data } = await columnList({
        currentPage: 1,
        pageSize: 1000,
      });
      const list = data.list.filter((item) => {
        return item.columnParentId === 0 || item.columnParentId === null;
      });
      list.map((item) => {
        item.label = item.columnName;
        item.value = item.columnId;
      });
      this.classAList = list;
      this.allColumnList = data.list;
    },
    handleSelectionChange(val) {
      var arr = [];
      val.map((item) => {
        arr.push(item.powerId);
      });
      this.delValue = arr;
    },
    onAllDelColumn() {
      if (this.delValue == "") {
        return this.$message({
          type: "error",
          message: "请选择要删除的数据!",
        });
      }
      this.$confirm("是否批量删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delPower({
            powerIdListStr: JSON.stringify(this.delValue),
          });
          if (data.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getPowerList();
          } else {
            this.$message({
              type: "error",
              message: "删除失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onAddPowerOk() {
      if (
        this.addFromList.powerName === "" ||
        this.addFromList.powerValue === "" ||
        this.addFromList.powerDescribe === "" ||
        this.value2 == ""
      ) {
        this.$message({
          type: "warning",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await addPower({
        powerName: this.addFromList.powerName,
        powerValue: this.addFromList.powerValue,
        powerDescribe: this.addFromList.powerDescribe,
        powerManageColumnId: this.value2,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.getPowerList();
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      this.getPowerList();
      this.addDialogVisible = false;
    },
    onEdit(row) {
      this.editDialogVisible = true;
      this.editFromList = row;
    },
    async onEditPowerOk() {
      if (
        this.editFromList.powerDescribe === "" ||
        this.editFromList.powerName === "" ||
        this.editFromList.powerValue === ""
      ) {
        this.$message({
          type: "warning",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await editPower(this.editFromList);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.getPowerList();
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      this.getPowerList();
      this.editDialogVisible = false;
    },
    async onDel(row) {
      this.$confirm("是否删除所有选中项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // this.delEditList.push(row.powerId);
          const { data } = await delPower({
            powerIdListStr: JSON.stringify([row.powerId]),
          });
          console.log(data);
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getPowerList();
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      this.delEditList = [];
    },
    handleSizeChange(sixe) {
      this.list.pageSize = sixe;
      this.getPowerList;
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getPowerList();
    },
  },
};
</script>
<style lang="less" scoped>
.powerList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 400px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
  }
  .el-dialog {
    .el-form {
      .el-cascader {
        width: 138px;
      }
    }
  }
}
</style>