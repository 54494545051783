import request from '../utils/request'
import baseUrl from './baseUrl'

export const powerList = (data) => request({
  url: baseUrl + '/power/findMangePowerList',
  method: 'POST',
  data
})
export const addPower = (data) => request({
  url: baseUrl + '/power/addManagePower',
  method: 'POST',
  data
})
//编辑权限
export const editPower = (data) => request({
  url: baseUrl + '//power/updateManagePower',
  method: 'POST',
  data
})
//删除权限
export const delPower = (data) => request({
  url: baseUrl + '/power/deleteManagePower',
  method: 'POST',
  data
})
// 给角色赋权
export const addPowerByRole = (data) => request({
  url: baseUrl + '/power/addOrDeletePowerByRole',
  method: 'POST',
  data
})
// 查询赋权列表 /column/findEmpowerList
export const roleEmpowermentList = (data) => request({
  url: baseUrl + '/column/findEmpowerList',
  method: 'POST',
  data
})